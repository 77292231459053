<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';

  import { css } from '$utils/utils';
  import SitesSelectorItem from '$components/common/sites-selector/sub-components/sites-selector-item.svelte';
  import Button from '$components/style/button/button.svelte';
  import type { PersonalizedPreferenceSitesArray } from '$stores/sites-store';

  export let suggestedPublications: PersonalizedPreferenceSitesArray;
  export let usedSiteRecommender: boolean;

  const wrapperStyle = css({
    maxWidth: '480px',
    margin: '0 auto',
    padding: '0 $x4',
  });

  const sectionStyle = css({
    padding: '$x4',
    backgroundColor: 'rgba(211, 217, 222, 1)',
    borderRadius: '$baseXl',
  });

  const headerStyle = css({
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '$x6',
  });

  const titleStyle = css({
    fontstyle: 'baseHeadlineL',
    textAlign: 'center',
    margin: '0 $x4',
  });

  const formStyle = css({
    backgroundColor: '$baseSubtleBg',
    borderRadius: '$baseXl',
    padding: '$x4',
  });

  const subtitleStyle = css({
    fontstyle: 'baseHeadlineXs',
    display: 'block',
    margin: '0 0 $x3',
  });

  const listStyle = css({
    margin: '0',
    padding: '0',
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '$x4',

    li: {
      margin: '0px',

      label: {
        margin: '0px',
      },
    },
  });

  const hardSitesList = suggestedPublications;

  const dispatch = createEventDispatcher();
</script>

{#if hardSitesList.length}
  <div class={wrapperStyle()} transition:fade|global={{ duration: 170 }}>
    <section class={sectionStyle()}>
      <header class={headerStyle()}>
        <h2 class={titleStyle()}>
          Velg dine favorittaviser slik at vi kan gi deg de mest relevante
          nyhetene.
        </h2>
        <Button
          on:click={() => dispatch('close')}
          clickLabel="sites-recommender"
          clickValue="sites-recommender-dismiss"
          title="Jeg vil ikke se flere forslag"
          type="transparent"
          icon="close-circle-thin"
          iconSize={24}
          size="none"
          blockPadding={0}
          inlinePadding={0}
        />
      </header>
      <form class={formStyle()}>
        <span class={subtitleStyle()}>Forslag</span>
        <ul class={listStyle()}>
          {#each hardSitesList.slice(0, 3) as { location, name, siteKey, domain } (siteKey)}
            <SitesSelectorItem
              checked={false}
              municipality={location?.municipality}
              county={location?.county}
              fullName={name.full}
              {siteKey}
              {domain}
              on:update={(ev) => {
                dispatch('update', { ...ev.detail });
                usedSiteRecommender = true;
              }}
            />
          {/each}
        </ul>
      </form>
    </section>
  </div>
{/if}
