<script lang="ts">
  import { css } from '$utils/utils';
  import '../untold-carousel/brick-carousel.wrapper';
  import Card from '$components/style/card/card.svelte';
  import ProfileCard from '$components/amedia/plus-alt-module/component/profile-card.svelte';

  export let logoSrc = '';
  export let title = '';
  export let subtitle = '';
  export let background: string | undefined = undefined;
  export let itemWidth = '45%';

  let carouselStyleOverride = '';

  export { carouselStyleOverride as class };

  const carouselWrapper = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '$x4',
  });
</script>

<Card padding="$x4 0px" {background} borderRadius="$baseL">
  <div class={carouselWrapper()}>
    <ProfileCard {logoSrc} {title} {subtitle}>
      <svelte:fragment slot="logo">
        <slot name="logo" />
      </svelte:fragment>
    </ProfileCard>

    <alt-carousel
      class={carouselStyleOverride}
      titleLevel="h2"
      data-width={itemWidth}
    >
      <slot />
    </alt-carousel>
  </div>
</Card>
