<script lang="ts">
  import { css } from '$utils/utils';
  import Search from '$components/style/search/search.svelte';
  import i18n from '$i18n/i18n.js';
  import type { ClientConfigSite } from '$stores/sites-store';

  export let publicationsList: ClientConfigSite[] = [];

  const paddingWrapper = css({
    paddingInline: '$x4',
    paddingBottom: '$x20',
  });

  const sectionStyle = css({
    marginInline: 'auto',
    maxWidth: '800px',
    marginTop: '$x8',
  });

  const titleStyle = css({
    fontstyle: 'baseHeadlineL',
    fontFeatureSettings: "'ss02'",
    margin: '0 $x4 $x10',

    '@bp500': {
      textAlign: 'center',
    },
  });

  const formStyle = css({
    margin: '0 $x4',
  });

  const listStyle = css({
    maxWidth: '600px',
    listStyle: 'none',
    margin: '$x10 auto 0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: '$x4',
  });

  const listItemStyle = css({
    padding: '$x2 $x4',
    borderRadius: '$baseXl',
    backgroundColor: '$supportiveSportBg',
    color: '$supportiveSportFg',
    fontstyle: 'baseMetaM',
    fontFeatureSettings: "'ss02'",
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '$x2',

    ':hover': {
      backgroundColor: '$supportiveSportSubtleFg',
      color: '$supportiveSportFg',
    },
  });

  const itemImageStyle = css({
    width: '32px',
    height: '32px',
    borderRadius: '$baseXl',
  });

  let inputValue = '';
</script>

<div class={paddingWrapper()}>
  <main class={sectionStyle()} id="main-content-begin">
    <h1 class={titleStyle()}>{$i18n.t('publicationslist.title')}</h1>
    <form class={formStyle()}>
      <Search
        bind:input={inputValue}
        placeholder={$i18n.t('publicationslist.search.placeholder')}
      />
    </form>
    <ul class={listStyle()}>
      {#each publicationsList.filter(({ name, domain, location }) => {
        if (inputValue.length === 1) return name.full
            .toLowerCase()
            .startsWith(inputValue.toLowerCase());
        const regex = new RegExp(inputValue, 'gi');
        return regex.test(name.full) || regex.test(name.short) || regex.test(domain.replace(/www\.([\w-]+)\.no/, '$1')) || regex.test(location?.municipality || '') || regex.test(location?.county || '');
      }) as { name, siteKey, domain }}
        <li>
          <a href="?sitekey={siteKey}#site-profile" class={listItemStyle()}
            ><img
              src="https://assets.acdn.no/local/v3/publications/{domain}/gfx/square.svg"
              alt="logo-{name.short}"
              class={itemImageStyle()}
            />
            {name.full}
          </a>
        </li>
      {/each}
    </ul>
  </main>
</div>
