<script lang="ts">
  import Teaser from './teaser.svelte';

  import { css } from '$utils/utils';
  import Button from '$components/style/button/button.svelte';
  import { Locations } from '$stores/router-store';
  import type { DomainKeys } from '$stores/sites-store';
  import { createTeaserStore } from '$stores/teaser-store';
  import { type TeaserStore } from '$types/teaser-types';

  const buttonWrapper = css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'calc(-1 * $x8)',
    width: '100%',
  });

  export let teaserList;
  export let domainKeys: DomainKeys;
  export let options;
  export let primarySite: string;
  export let filter = '';

  let { resumeId } = options;

  const teasers = createTeaserStore({
    teaserList,
    resumeId,
    initialFilter: filter,
  });

  const getRandomTopFive = (store: TeaserStore) => {
    const data = store.data || [];
    const dataOnlyInternal = data.filter(
      (teaser) => teaser.renderType === 'internal'
    );
    const index = Math.floor(Math.random() * 4);
    return dataOnlyInternal[index];
  };
</script>

{#await getRandomTopFive($teasers) then teaser}
  {#if teaser && teaser.renderType === 'internal'}
    <Teaser
      siteKey={domainKeys[teaser.publication]}
      {...teaser}
      {primarySite}
      style={teaser.ratioType === 'wide'
        ? '16/9'
        : teaser.ratioType === 'square'
          ? '4/3'
          : null}
      adType="midtbanner"
    />
    <div class={buttonWrapper()}>
      <Button
        title="Se flere gode saker i Populært-feeden"
        clickLabel="explore-in-home"
        clickValue="open-explore-feed"
        icon="arrow-right"
        href={`/?#${Locations.Explore}`}
      >
        Se flere gode saker i Populært-feeden!
      </Button>
    </div>
  {/if}
{/await}
